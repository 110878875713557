.navBar {
  display: flex;
  width: 15.104vw;
  height: 96vh;
  padding: 1.979vw 0.781vw 0vw 0.781vw;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.navBar .top_navbar {
  width: 13.542vw;
  height: 35.625vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navBar .top_navbar img {
  width: 10.521vw;
}

.navBar .top_navbar .search_bar_cont {
  margin-top: 2.604vw;
  width: 13.073vw;
  height: 2.083vw;
  position: relative;
}

.navBar .top_navbar .search_bar_cont input {
  width: 100%;
  padding-left: 1.771vw;
  height: 100%;
  border-radius: 1.042vw;
  background: #f2f2f2;
  color: #545454;
  font-family: "DM Sans";
  font-size: 0.729vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.042vw;
  letter-spacing: -0.015vw;
  border: none;
  outline: none;
}
.navBar .top_navbar .search_bar_cont input::placeholder {
  color: #b0b0b0;
}

.navBar .top_navbar .search_bar_cont svg {
  position: absolute;
  left: 0.781vw;
  top: 50%;
  transform: translate(0%, -50%);
}

.navBar .top_navbar .first_top_group {
  display: flex;
  width: 11.979vw;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 1.25vw;
  margin-top: 1.042vw;
}

.navBar .top_navbar .first_top_group h1 {
  color: #b3b3b3;
  font-family: "DM Sans";
  font-size: 0.729vw;
  font-style: normal;
  font-weight: 400;
  line-height: 1.042vw;
  letter-spacing: -0.015vw;
  align-self: stretch;
}

.navBar .top_navbar .first_top_group h2 {
  color: #b4b4b4;
  font-family: "Poppins";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  align-self: stretch;
}

.navBar .top_navbar .first_top_group .cont_first_top_group {
  display: flex;
  width: 11.563vw;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.563vw;
}

.navBar .top_navbar .first_top_group .cont_first_top_group button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.781vw;
  border: none;
  outline: none;
  background: transparent;
  color: #b4b4b4;
  font-family: "DM Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.563vw; /* 187.5% */
  letter-spacing: -0.017vw;
  transition: 0.7s;
}
.navBar .top_navbar .first_top_group .cont_first_top_group button:hover {
  transform: translateX(10%) scale(1.05);
}
.navBar .top_navbar .first_top_group .cont_first_top_group button svg {
  transition: 0.8s;
}

.selected_nav_button {
  width: 11.458vw !important;
  height: 2.5vw !important;
  border-radius: 0.781vw !important;
  background: #7749f8 !important;
  box-shadow: 0vw 1.042vw 2.083vw -0.521vw rgba(112, 144, 176, 0.12) !important;
  color: white !important;
  justify-content: flex-start !important;
  padding-left: 1.302vw;
}
.selected_nav_button:before {
  position: absolute;
  content: "";
  bottom: -0.417vw;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 11.25vw;
  height: 1.823vw;
  flex-shrink: 0;
  border-radius: 0.781vw;
  background: rgba(119, 73, 248, 0.15);
  box-shadow: 0vw 1.042vw 2.083vw -0.521vw rgba(112, 144, 176, 0.12);
}
.selected_nav_button svg path {
  fill: white !important;
}
.selected_nav_button:hover {
  transform: translateX(0%) scale(1) !important;
}
.navBar .top_navbar .div_bar {
  width: 12.292vw;
  height: 0.052vw;
  background: #dfe5f2;
  margin-top: 2.396vw;
}

.navBar .top_navbar .first_top_group:nth-child(2) {
  margin-top: 1.667vw;
}

.navBar .bottom_navbar {
  width: 13.125vw;
  height: 2.292vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.navBar .bottom_navbar button {
  width: 100%;
  height: 100%;
  border-radius: 0.781vw;
  background: #7849f811;
  box-shadow: rgba(120, 73, 248, 0.116) x 2.083vw -0.521vw rgba(112, 144, 176, 0.12);
  color: #7749f8;
  font-family: "DM Sans";
  font-size: 0.833vw;
  font-style: normal;
  font-weight: 500;
  line-height: 1.563vw; /* 187.5% */
  letter-spacing: -0.017vw;
  border: none;
  outline: none;
  text-align: left;
  padding-left: 0.99vw;
  display: flex;
  align-items: center;
  gap: 0.833vw;
}
